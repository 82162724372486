import { Application } from "@hotwired/stimulus"
const application = Application.start()

// Stimulus components
import RevealController from "stimulus-reveal"
application.register("reveal", RevealController)

import DropdownController from "./dropdown_controller"
application.register("dropdown", DropdownController)

// Configure Stimulus development experience
application.debug = false
window.Stimulus   = application

export { application }
